<template>
	<div id="app" v-if="isLoading">
		<Head @custom-event="ChangeAccount" />
		<router-view ref="routerView" />
	</div>
	<div v-else class="connect_wallet">
		<div class="logo_box">
			<img src="./assets/logo2.png" class="logo">
		</div>
		<div class="box">
			<div class="load_box" v-if="repair===false">
				<van-button v-if="reConnect" type="primary" @click="cw" src="./assets/logo2.png" style="width:150px !important;height: 40px !important;border-radius: 24px !important;">链接钱包</van-button>
				<img v-else src="./assets/loading.gif" class="loading_img">
			</div>
			<div class="toast" v-else>
				<div class="lang">
					<span @click="language = 'zh'">中</span>
					<span class="gap"></span>
					<span @click="language = 'en'">EN</span>
				</div>
				<div class="info zh" v-if="language == 'zh'">
					<div class="title">我們正在陞級</div>
					<div>為更好的提供用戶交易體驗，海豚交易所將進行系統升級，陞級期間將暫停交易服務。</div>
					<div>陞級時間：2024年9月21日0點至2024年9月22日0點</div>
					<div>感謝你對海豚交易所的支持，我們將儘快完成系統升級並恢復交易！</div>
					<div class="time">——2024-09-21</div>
				</div>

				<div class="info" v-if="language == 'en'">
					<div class="title">We are upgrading</div>
					<div>For a better trading experience, Dolphin Exchange will undergo a system upgrade. During this period, trading services will be suspended.</div>
					<div>Upgrade period: September 21, 2024, 00:00 to September 22, 2024, 00:00</div>
					<div>Thank you for your support of Dolphin Exchange. We will complete the system upgrade as soon as possible and restore trading!</div>
					<div class="time">——2024-09-21</div>
				</div>
			</div>
			<div class="title1">海豚交易所</div>
			<div class="title2">Dolphin Exchange</div>
		</div>
	</div>
</template>
<script>
import Head from '@/components/Head.vue';
import { Notify } from 'vant-green';
export default {
	components: {
		Head: Head,
	},
	data() {
		return {
			contract: null,
			isLoading:false,
			reConnect:false,
			language: 'zh',
			repair:false
		};
	},
	mounted() {
		if(this.repair===false){
			setTimeout(async ()=>{
				await this.cw()
			}, 100)
		}
	},
	
	methods: {
		async cw(){
			try {
				this.reConnect = false;
				await this.get_chain_info();
				if(!this.$store.state.config){
					return;
				}

				const account = await this.$connectWallet(this.$store.state.config)
				console.log("Address:",account)
				
				//从本地存储中获取之前选择的代币
				const last_token = localStorage.getItem("last_token")
				if(last_token){
					this.$store.state.this_token = JSON.parse(last_token)
				}

				if(account){
					this.init();
				}else{
					throw this.$i18n.t("methods.t0");
				}
			} catch (error) {
				this.reConnect = true;
			}
		},
		ChangeAccount(account) {
			console.log("account changed:",account)
		},
		async get_chain_info(){
			try {
				const result = await this.$api.request('/get_chain_info');
				if(result.code==200){
					this.$store.state.config = result.data;

					if(!this.$store.state.this_token){
						this.$store.state.this_token = result.data.Tokens[0];
					}
				}else{
					this.$store.state.config = null;
					Notify({
						type: 'danger',
						message: result.msg,
					})
				}
			} catch (error) {
				this.$store.state.config = null;
				console.log(error);
				Notify({
					type: 'danger',
					message: 'Network error',
				})
			}
			
			return this.$store.state.config;
		},
		async init(){
			Promise.all([this.getTokenInfo(), this.getFee()]).then(result2=>{
				setTimeout(()=>{
					this.isLoading = true;
				},500)
			}).catch(error=>{
				Notify({
					type: 'danger',
					message: 'Network error',
				})
				console.error('初始化錯誤:', error);
				this.reConnect = true;
			})
		},
		
		async getFee(){
			try {
				this.contract = new this.$BlockchainContract(this.$store.state.config);
				this.$store.state.FeeRate = await this.contract.getFee();
			} catch (error) {
				console.log(error);
				throw error;
			}
			return this.$store.state.FeeRate;
		},

		async getTokenInfo() {
			try {
				const result = await this.$api.request('/get_token_info', this.$store.state.this_token, "POST");
				if(result.code==200){
					this.$store.state.token_info = result.data;
					if(!result.data.name){
						this.$store.state.this_token = this.$store.state.config.Tokens[0];
						this.getTokenInfo();
					}
				}
			} catch (error) {
				console.log(error);
				throw error;
			}
			
			return this.$store.state.token_info;
		},
	
	}
};
(function () {
	function setRemUnit() {
		const docEl = document.documentElement;
		const width = docEl.clientWidth;

		// 设置设计稿宽度，这里假设设计稿宽度为375px
		const designWidth = 375;

		// 设置根元素字体大小为 (当前屏幕宽度 / 设计稿宽度) * 初始字体大小
		docEl.style.fontSize = (width / designWidth) * 12 + 'px';
	}

	// 初始化时设置一次
	setRemUnit();

	// 窗口大小改变时重新设置
	window.addEventListener('resize', setRemUnit);
})();
</script>
<style scoped lang="scss">
.connect_wallet{
	background-color: #2652e9;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	.logo_box{
		flex-grow: 1;
		display: flex;

	}
	.logo{
		width: 35%;
		margin: auto;
	}
	.logo2{
		height: 22px;
	}
	.box{
		border-radius: 20px;
		text-align: center;
		padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
		.load_box{
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.loading_img{
		height: 100%;
	}
	.title1{
		margin-top: 40px;
		// font-family: PingFang-SC-Medium;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 4px;
		color: #fff;
	}
	.title2{
		margin-top: 13px;
		// font-family: PingFang-SC-Medium;
		font-size: 16px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 2px;
		color: #dedede;
	}
}
.loading_btn{
	width: 44px;
	height: 44px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
}
.toast{
	padding: 25px 15px;
	margin: 0 15px;
	border-radius: 10px;
	max-height: 400px;
	min-height: 300px;
	overflow: hidden auto;
	background: #fff;
	text-align: left;
	position: relative;
	margin-bottom: 100px;
	
	>.info{
		line-height: 24px;

		.title{
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			margin: 10px 0 15px;
		}
		div{
			font-size: 14px;
		}
		.time{
			text-align: right;
			color: #666;
			margin-top: 30px;
			position: absolute;
			bottom: 15px;
			right: 15px;
		}
	}
	>.info.zh{
		font-family: cursive;
		line-height: 28px !important;
		letter-spacing: 1px;
	}
	
	.lang{
		display: flex;
		position: absolute;
		right: 10px;
		top: 10px;
		gap: 10px;
		align-items: center;
		font-size: 15px;
		.gap{
			width: 1px;
			background: #000;
			height: 10px;
		}
	}
}
</style>
<style lang="scss">
html {
	background: #fff;
	max-width: 500px;
	margin: 0 auto;
	font-size: 12px;
	overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	background: rgb(242, 242, 242);
	background-size: 100%;
	min-height: 100vh;
}

.df{
	display: flex;
}
.jcsb{
	justify-content: space-between;
	align-items: center;
}
.green{
	color: #25b769 !important;
}
.red{
	color: #F44336 !important;
}

.default_btn {
	background:#2553ea;
	border-radius: 12px;
	// font-family: PingFang-SC-Bold;
	letter-spacing: 0px;
	width: 100%;
	transition: 300ms;
	user-select: none;
	border: none !important;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px !important;
	height: 40px !important;
    opacity: .9 !important;

	&:active {
		transform: scale(0.98);
		opacity: 0.85;
	}

	
}
.cancel{
	background: #151515;
    border: 1px solid #ff9c12 !important;
    color: #ff9c12 !important;
	height: 38px !important;
}
.hollow_btn{
	width: 107px;
	height: 32px;
	border-radius: 6px;
	border: solid 1px #ffa511;
	font-size: 14px !important;
	text-align: center;
	line-height: 32px;
	// font-family: PingFang-SC-Bold;
	font-size: 14px !important;
	letter-spacing: 0px;
	color: #ffa511 !important;
	margin: auto;
	transition: 300ms;
	user-select: none;

	&:active {
		transform: scale(0.98);
		opacity: 0.85;
	}
}
.dialog_box {
	text-align: left;
	line-height: 24px;
	padding: 0px 18px 0px 20px;
	color: #000;
	font-size: 13px;

	display: flex;
    flex-direction: column;
    flex-grow: 1;
	.content_box{
		flex-grow: 1;
	}
	.btn_box{
		// padding: 40px 0 30px;
	}
}
.van-button{
	border-radius: 15px !important;
	height: 30px !important;
	// padding: 0 !important;
}

.van-cell{
	border: none !important;
    background: none!important;
    padding: 0!important;


}

.van-field__button{
	width: 60px;
	height: 32px;
}

.my_sticky{
	position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
}

.van-sticky{
	background: transparent !important;
	padding: 10px 0px !important;
	transition: 500ms;
}
.van-sticky.van-sticky--fixed{
	background: rgb(255, 255, 255) !important;
	padding: 10px 16px !important;
	box-shadow: 0px 1px 5px 0px #d6d6d6 !important;
	max-width: calc(500px - 32px);
    margin: auto;
}
.van-tabs--line .van-tabs__wrap{
	height: 36px !important;
	border-radius: 18px !important;
}

.van-tab--active{
	background-color: rgba(37, 83, 234, 1);
	border-radius: 18px;
}

.van-notify {
  z-index: 3000 !important; /* 覆盖 Notify 的 z-index */
}
.none {
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	user-select: none;
	color: #999;
	padding-bottom: 40px;
	font-size: 14px;
	img {
		width: 120px;
		margin-bottom: 10px;
	}
}
</style>
