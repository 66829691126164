<template>
	<div class="orderView">
		<div class="my_tabs">
			<!-- t1: "当前委托" -->
			<div @click="changeTab(1)" ref="tab1" :class="{ active: type == 1 }">{{ $t("order.t1") }}</div>
			<!-- t2: "历史委托" -->
			<div @click="changeTab(2)" ref="tab2" :class="{ active: type == 2 }">{{ $t("order.t2") }}</div>
			<!-- t3: "购买订单" -->
			<div @click="changeTab(3)" ref="tab3" :class="{ active: type == 3 }">{{ $t("order.t3") }}</div>
			<div class="line" :style="`--x:${x}px`"></div>
		</div>
		
		<div class="list" v-if="list.length > 0">
			<div class="item" v-for="(item, index) in list" :key="index">
				<div class="token">
					<div class="name">
						<img :src="this_token.tokenImg" alt="" />
						<div class="price_box">
							<div class="number">{{ $utils.numToK(item.amount1, 4, 2) }} {{ this_token.name }}</div>
							<div class="price1">{{ $utils.numToK(item.price1, 4, 0) }} {{ this_token.pair_name }} / {{ this_token.name }}</div>
						</div>
					</div>
					<span>{{ $utils.numToK(item.amount1 * item.price1, 8, 2) }} {{ this_token.pair_name }}</span>
				</div>
				<div class="info">
					<div>
						<div>
							<!-- t4: "编号" -->
							<span>{{ $t("order.t4") }}</span>
							<span>#{{ handle_id(item.orderId) }}</span>
						</div>
						<div v-if="type == 1 || type == 2">
							<!-- t5: "挂单时间" -->
							<span>{{ $t("order.t5") }}</span>
							<span>{{ handle_time(item.sell_time) }}</span>
						</div>
						<div v-if="type == 3">
							<!-- t6: "交易时间" -->
							<span>{{ $t("order.t6") }}</span>
							<span>{{ handle_time(item.buy_time) }}</span>
						</div>
						<div>
							<!-- t7: "交易Hash" -->
							<span>{{ $t("order.t12") }}</span>
							<span class="hash" @click="toHash(item.hash)">{{ handle_hash(item.hash) }}</span>
						</div>
						<div v-if="type == 2 || type == 3">
							<!-- t7: "交易Hash" -->
							<span>{{ item.status == 2 ? $t("order.t13") : $t("order.t14") }}</span>
							<span class="hash" @click="toHash(item.buy_hash)">{{ handle_hash(item.buy_hash) }}</span>
						</div>
					</div>
					<van-button @click="cancelOrder(item, index)" type="info" plain v-if="type == 1" :loading="cancelLoading && cancelIndex == index" :loading-text="cancelLoadingTXT">{{ $t("order.t10") }}</van-button>
					<van-button plain v-if="type == 2">{{ item.status == 2 ? $t("order.t8") : $t("order.t9") }}</van-button>
				</div>
			</div>
		</div>
		<div v-else class="none">
			<img src="../assets/no_record.png" />
			<!-- 暫無數據 -->
			<div>{{ $t("order.t11") }}</div>
		</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
import BigInt from "big-integer";
export default {
	name: "OrderView",
	props: {
		contract: null,
	},
	data() {
		return {
			page:1,
			type: 1,
			x: 0,
			this_token: this.$store.state.this_token,
			list: [],
			cancelIndex: "",
			cancelLoading: false, //确认购买loading
			cancelLoadingTXT: "", //确认购买loading文本
			language: this.$store.state.language,
		};
	},
	async mounted() {
		//查询列表
		this.getData();
		const rect = this.$refs[`tab${this.type}`].getBoundingClientRect();
		this.x = rect.width / 2 - 7;
	},
	watch: {
		"$store.state.changeNum": function() {
			setTimeout(()=>{
				this.computeX(this.type)
			},0)
		},
	},
	methods: {
		changeTab(type) {
			this.type = type;
			this.computeX(type);
			this.onRefresh();
		},
		computeX(type) {
			//获取这个div的位置
			const rect = this.$refs[`tab${type}`].getBoundingClientRect();
			this.x = rect.x + rect.width / 2 - 15 - 7;
		},
		hashTo7Chars(hash) {
			// 将 hash 转换为整数
			const hashInt = BigInt(Number(hash)); // 处理大整数
			const base36String = hashInt.toString(36); // 转换为 Base36
			const base36Length = base36String.length;

			// 如果长度大于 7，则通过算法映射到 7 位
			let result = "";
			for (let i = 0; i < 7; i++) {
				const index = (hashInt % BigInt(base36Length - i)).toString(); // 使用余数来获取字符
				result += base36String[index];
			}

			return result;
		},
		handle_id(id) {
			return this.$utils.idComplex(id, 18);
		},
		handle_time(time) {
			return new Date(time * 1000).toLocaleString();
		},
		handle_hash(hash) {
			return hash.substr(0, 10) + "...." + hash.substr(hash.length - 8);
		},

		async getData() {
			try {
				const result = await this.$api.request(
					"/get_my_order",
					{
						address: this.this_token.address,
						account: localStorage.getItem("userAccount"),
						type: this.type,
						page: this.page
					},
					"POST"
				);
				if (result.code == 200) {
					this.list = result.data;
				} else {
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("home.t33"),
				});
			}
		},
		async onRefresh(reload = true, pageadd=false) {
			this.page = pageadd ? this.page + 1 : 1;
			
			if (reload) {
				this.list = [];
				this.this_token = this.$store.state.this_token;
			}
			this.getData();
		},
		async cancelOrder(item, index) {
			this.cancelIndex = index;
			this.cancelLoading = true;
			try {
				const result = await this.contract.cancel(item, this);
				if (result && result.hash) {
					Notify({
						type: "success",
						message: "撤回成功", //Success
					});
					this.list.splice(index, 1);
				} else {
					Notify({
						type: "danger",
						message: "撤回失败", //Network error
					});
				}
			} catch (error) {
				Notify({
					type: "danger",
					message: "撤回失败", //Network error
				});
			}
			this.cancelLoading = false;
		},
		toHash(hash) {
			window.open(this.$store.state.config.blockExplorerUrls[0] + "tx/" + hash);
		},
	},
};
</script>

<style scoped lang="scss">
.sort {
	padding: 15px 0;
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 4px;
	img {
		width: 8px;
	}
}

.item {
	padding: 0 16px;
	margin-bottom: 5px;
	background-color: #fff;
	border-radius: 10px;

	.token {
		font-size: 14px;
		color: #030c03;
		border-bottom: solid 1px #e5e5e5;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name {
			display: flex;
			align-items: center;
			gap: 7px;
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
			.price_box {
				display: flex;
				flex-direction: column;
				gap: 5px;
				line-height: 1;
				.number {
					font-size: 16px;
					font-weight: normal;
					color: #030c03;
				}
				.price1 {
					font-size: 12px;
					font-weight: normal;
					color: #808080;
				}
			}
		}
	}

	.price {
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #030c03;
		> div {
			display: flex;
			flex-direction: column;
			gap: 10px;
			line-height: 1;
			> div {
				display: flex;
				gap: 10px;
			}
		}
	}

	.info {
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #030c03;
		> div {
			display: flex;
			flex-direction: column;
			gap: 10px;
			line-height: 1;
			> div {
				display: flex;
				gap: 10px;
			}
		}
	}
}

.my_tabs {
	--gap: 25px;
	height: 44px;
	display: flex;
	align-items: center;
	gap: var(--gap);
	margin-bottom: 5px;
	border-radius: 10px;
	font-size: 12px;
	color: #030c03;
	position: relative;

	> div.active {
		color: #2553ea;
	}
	.line {
		transition: 300ms;
		position: absolute;
		// left: calc(48px * var(--ins) + var(--ins) * var(--gap));
		left: 0;
		right: 0;
		bottom: 6px;
		height: 2px;
		background: #2553ea;
		width: 14px;
		border-radius: 4px;
		transform: translateX(var(--x));
		// margin-left: -21px;
	}
}
.hash {
	color: #1989fa;
}
</style>
