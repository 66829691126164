import {
    Loading,
    Toast,
    Icon,
    Checkbox,
    Dialog,
    Button,
    Form,
    Field,
    Skeleton,
    Uploader,
    Image as VanImage,
    Pagination,
    DropdownMenu,
    DropdownItem,
    Picker,
    Overlay,
    Tab,
    Tabs,
    Sticky,
    ActionSheet,
    Popup,
    PullRefresh,
    List
} from 'vant';

const Vant = {
    install(Vue) {
        Vue.use(Loading);
        Vue.use(Toast);
        Vue.use(Icon);
        Vue.use(Checkbox);
        Vue.use(Dialog);
        Vue.use(Button);
        Vue.use(Form);
        Vue.use(Field);
        Vue.use(Skeleton);
        Vue.use(Uploader);
        Vue.use(VanImage);
        Vue.use(Pagination);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(Picker);
        Vue.use(Overlay);
        Vue.use(Tab);
        Vue.use(Tabs);
        Vue.use(Sticky);
        Vue.use(ActionSheet);
        Vue.use(Popup);
        Vue.use(PullRefresh);
        Vue.use(List);
    }
};

export default Vant;