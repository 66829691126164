import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { connectWallet } from '@/utils/connectWallet.js';
Vue.prototype.$connectWallet = connectWallet;
import BlockchainContract from '@/utils/methods.js';
Vue.prototype.$BlockchainContract = BlockchainContract;
// axios
import axios from 'axios'
Vue.prototype.$axios = axios

//i18n
import i18n from './utils/i18n.js';

//vant
import Vant from './utils/vant.js';
import 'vant/lib/index.css'; // 引入 Vant 样式文件
import './styles/variables.scss'; // 引入自定义样式文件
Vue.use(Vant);

//全局引入utils.js
import * as utils from './utils/utils';
Vue.prototype.$utils = utils;

Vue.config.productionTip = false

import { fetchData, postData, request } from './api/api';
Vue.prototype.$api = {
  fetchData,
  postData,
  request
};
Vue.config.errorHandler = function (err, vm, info) {
	// console.error(err, info);
	// 空函数，不做任何操作
};

Vue.config.warnHandler = function (msg, vm, trace) {
	// console.warn(msg, trace);
	// 空函数，不做任何操作
};

window.addEventListener('unhandledrejection', event => {
    // console.log('全部错误收集：', event);
});
new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
