<template>
	<div class="list" v-if="list.length > 0">
		<div class="item" v-for="(item, index) in list" :key="index">
			<div class="token">
				<div class="name">
					<img :src="this_token.tokenImg" alt="">
					<div class="price_box">
						<div class="number">{{ $utils.numToK(item.amount1, 4, 2) }} {{ this_token.name }}</div>
						<div class="price">{{ $utils.numToK(item.price1, 4, 0) }} {{ this_token.pair_name }} / {{ this_token.name }}</div>
					</div>
				</div>
				<!-- t1: 成交 -->
				<span>{{ $t('trade.t1') }}</span>
			</div>
			<div class="info">
				<div>
					<div>
						<!-- t2: 总价 -->
						<span>{{ $t('trade.t2') }}</span>
						<span>{{ $utils.numToK(item.amount1 * item.price1, 8, 2) }} {{ this_token.pair_name }}</span>
					</div>
					<div>
						<!-- t3: 编号 -->
						<span>{{ $t('trade.t3') }}</span>
						<span>#{{ handle_id(item.orderId) }}</span>
					</div>
					<div>
						<!-- t4: 时间 -->
						<span>{{ $t('trade.t4') }}</span>
						<span>{{ handle_time(item.buy_time) }}</span>
					</div>
					<div>
						<!-- t5: 交易Hash -->
						<span>{{ $t('trade.t5') }}</span>
						<span class="hash" @click="toHash(item.buy_hash)">{{ handle_hash(item.hash) }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="none">
		<img src="../assets/no_record.png" />
		<!-- 暫無數據 -->
		<div>{{ $t("trade.t6") }}</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
export default {
	name: 'TradeView',
	props: {
	},
	data() {
		return {
			this_token: this.$store.state.this_token,
			list: [],
		};
	},
	async mounted() {
		//查询列表
		this.getData();
	},
	methods: {
		handle_id(id) {
			//把id转为hash，不足6位前面补0
			return this.$utils.idComplex(id, 18);
		},
		handle_time(time) {
			return new Date(time * 1000).toLocaleString();
		},
		handle_hash(hash) {
			return hash.substr(0, 10) + "...." + hash.substr(hash.length - 8);
		},
		async getData() {
			try {
				const result = await this.$api.request(
					"/get_trade_list",
					{
						address: this.this_token.address,
					},
					"POST"
				);
				if (result.code == 200) {
					this.list = result.data;
				} else {
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (error) {
				console.log(error);
				Notify({
					type: "danger",
					message: this.$t("home.t33"),
				});
			}
		},
		async onRefresh(reload = true) {
			if(reload) this.list = [];
			this.this_token = this.$store.state.this_token;
			this.getData();
		},
		toHash(hash){
			window.open(this.$store.state.config.blockExplorerUrls[0]+'tx/'+hash)
		},
	},
}
</script>

<style scoped lang="scss">
.item{
	padding: 0 16px;
	margin-bottom: 5px;
	background-color: #fff;
	border-radius: 10px;

	.token{
		font-size: 14px;
		color: #030c03;
		border-bottom: solid 1px #e5e5e5;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name{
			display: flex;
			align-items: center;
			gap:7px;
			img{
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
			.price_box{
				display: flex;
				flex-direction: column;
				gap: 5px;
				line-height: 1;
				.number{
					font-size: 16px;
					font-weight: normal;
					color: #030c03;
				}
				.price{
					font-size: 12px;
					font-weight: normal;
					color: #808080;
				}
			}
		}
		
		
		
	}

	.info{
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #030c03;
		>div{
			display: flex;
			flex-direction: column;
			gap: 10px;
			line-height: 1;
			>div{
				display: flex;
				gap: 10px;
			}
		}
	}
}
.hash{
	color: #1989fa;
}
</style>
