<template>
	<div class="header">
		<img src="../assets/logo.png" class="logo" @click="toHome()" />
		<div class="wallet" @click="logout">
			<img src="../assets/wallet.png" />
			<span>{{ userAccount | address }}</span>
		</div>
		<div class="language" @click="setLanguage">
			<img :src="`/static/${language}.png`" style="width: 20px" />
			<span>{{ language_name }}</span>
		</div>

		<!-- 斷開連接 -->
		<van-dialog v-model="show_logout" overlay-class="mydialog" class="dialog1" :title="$t('head.t3')" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_box">
				<div class="content_box">
					<div>{{ $t("head.t4") }}</div>
				</div>
				<div class="btn_box">
					<van-button plain @click="show_logout = false">{{ $t("head.t5") }}</van-button>
					<van-button type="info" @click="confirmLogout">{{ $t("head.t6") }}</van-button>
				</div>
			</div>
		</van-dialog>

		<van-popup v-model="showLanguage" round position="bottom">
			<van-picker show-toolbar :columns="columns" value-key="text" @cancel="showLanguage = false" @confirm="onConfirm" :default-index="language_index" :cancel-button-text="$t('head.t5')" :confirm-button-text="$t('head.t6')"/>
		</van-popup>
	</div>
</template>

<script>
//加载img
export default {
	name: "HelloWorld",
	props: {
		msg: String,
	},
	data() {
		return {
			userAccount: "", //用户账户
			language: "zh-hant", //语言
			language_name: "繁體中文",
			language_index: 0,
			show_logout: false,
			showLanguage: false,
			columns: [
				{key: "zh-hant", text: "繁體中文", name: "繁體"},
				{key: "en", text: "English", name: "EN"},
				{key: "ja", text: "日本語", name: "日語"},
				{key: "zh", text: "简体中文", name: "简体"},
			],
		};
	},
	async mounted() {
		//获取语言设置
		this.language = localStorage.getItem("language") || "zh-hant";
		this.language_name = localStorage.getItem("language_name") || "繁體";
		this.language_index = localStorage.getItem("language_index") || 0;
		// 切换语言
		this.$i18n.locale = this.language;
		this.userAccount = localStorage.getItem("userAccount");
	},
	filters: {
		address(value) {
			if (!value) return "";
			return value.slice(0, 6) + "..." + value.slice(-4);
		},
	},
	methods: {
		
		toHome() {
			window.location.reload();
			window.location.href = ''
			setTimeout(() => {
				window.location.href = window.location.origin + "/#/";
			},100)
		},
		//切换语言
		setLanguage() {
			this.showLanguage = true;
		},
		onConfirm(value,index) {
			// console.log(value,index);
			this.showLanguage = false;
			this.language_index = index;
			this.language = value.key;
			this.language_name = value.name;
			// 切换语言
			this.$i18n.locale = this.language;
			this.$store.state.language = value.key;
			this.$store.state.changeNum += 1;
			// 保存语言设置
			localStorage.setItem("language_index", this.language_index);
			localStorage.setItem("language", this.language);
			localStorage.setItem("language_name", value.name);
		},
		//点击复制地址
		copy_address() {
			this.$copyText(this.userAccount).then(() => {
				this.$toast(this.$t("head.t2"));
			});
		},
		//断开链接
		logout() {
			this.show_logout = true;
		},
		confirmLogout() {
			localStorage.removeItem("signature_"+this.userAccount);
			window.location.reload();
			window.location.href = ''
			setTimeout(() => {
				window.location.href = window.location.origin + "/#/";
			},100)
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
	background: #2553ea;
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0 15px;
	height: 50px;
	align-items: center;

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	max-width: calc(500px - 24px);
	margin: auto;
	.logo {
		height: 22px;
	}
	.wallet {
		display: flex;
		align-items: center;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		width: fit-content;
		border: solid 1px #999999;
		border-radius: 12px;
		padding: 0 10px;
		height: 24px;
		img {
			width: 14px;
		}
		span {
			margin: 0 4px;
			// font-family: PingFang SC;
			font-size: 12px;
			font-weight: bold;
			color: #fff;
		}
	}
	.language {
		width: 66px;
		height: 24px;
		background: #ffffff;
		border: 1px solid #089553;
		border-radius: 12px;
		display: flex;
		align-items: center;
		padding: 0 3px;
		img {
			width: 20px;
			border-radius: 50%;
		}
		span {
			margin: 0 auto;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #089553;
		}
	}
}

.content_box {
	margin-top: 12px !important;
}
.btn_box {
	padding: 20px 0 30px !important;
	display: flex;
	gap: 10px;
	> button {
		width: 100%;
	}
}
</style>
