import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading:null,
    changeNum:0,
    tabRefIndex:0,
    address0:'0x0000000000000000000000000000000000000000'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
